import React from 'react'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import { useGetHrefContext } from '@scentregroup/shared/context'
import { getDefaultCentreMetadata } from '@scentregroup/shared/helpers/get-default-centre-metadata'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import Layout from '@scentregroup/shared/hub-components/layout'
import {
  getCentreDetails,
  CentreDetails,
  buildContactLinks,
} from '@scentregroup/shared/helpers/centre'
import { useStoredCentre } from '@scentregroup/shared/hooks/use-stored-centre'
import { SGLinkedData } from '@scentregroup/shared/hub-components/json-ld'
import {
  GetServerSidePropsResult,
  GetServerSidePropsContext,
} from '../../types/next'
import { makeApolloClient } from '../../lib/apolloClient'
import { getCentrePageTemplate } from '../../lib/page-templates/fetching'
import { OpenGraph } from '../../lib/open-graph'
import {
  getTopStripProps,
  ITopStripProps,
} from '@scentregroup/shared/hub-components/top-strip'
import { Template } from '@scentregroup/shared/components/page-templates/template'
import { getCentreTradingHoursStartingNow } from '../../lib/get-trading-hours'
import { TradingHourDay } from '@scentregroup/shared/graphql'
import { shouldRedirectForMismatchedRoute } from '@scentregroup/shared/lib/redirects'
import { useQuickLinksFromTemplate } from '../../components/page-templates/use-quicklinks-from-template'
import { SGPageTemplateWithAdZones } from '@scentregroup/shared/types/page-templates'
import {
  CentreStaticData,
  fetchCentreStaticData,
} from '@scentregroup/shared/helpers/fetch-header-static-data'

const TRADING_HOUR_DAYS = 7

export async function getServerSideProps(
  ctx: GetServerSidePropsContext<{
    centre: string
  }>
): Promise<GetServerSidePropsResult<ICentreHomePageProps>> {
  setCacheHeaders(ctx.res)
  const country = localeToCountry(ctx.locale)
  const centreSlug = ctx.params?.centre
  if (!centreSlug) {
    return {
      notFound: true,
    }
  }

  const apollo = makeApolloClient()

  const centre = await getCentreDetails(apollo, { slug: centreSlug, country })
  if (!centre) {
    return {
      notFound: true,
    }
  }

  const redirectForCentreSlug = shouldRedirectForMismatchedRoute(
    '/[centre]',
    ctx.params,
    {
      centre: centre.slug,
    }
  )
  if (redirectForCentreSlug) {
    return redirectForCentreSlug
  }

  const [template, headerStaticData, topStripProps, tradingHours] =
    await Promise.all([
      getCentrePageTemplate(apollo, centreSlug, 'centre'),
      fetchCentreStaticData(apollo, {
        country,
        centre,
      }),
      getTopStripProps(apollo, country, centreSlug),
      getCentreTradingHoursStartingNow(apollo, centre, TRADING_HOUR_DAYS),
    ])
  if (!template) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      country,
      topStripProps,
      centre,
      template,
      headerStaticData,
      tradingHours,
    },
  }
}

export interface ICentreHomePageProps {
  country: ReturnType<typeof localeToCountry>
  topStripProps: ITopStripProps | null
  centre: CentreDetails
  template: SGPageTemplateWithAdZones
  headerStaticData: CentreStaticData
  isBasicPage?: boolean
  tradingHours: TradingHourDay[]
}

export default function CentreHomePage(
  props: ICentreHomePageProps
): JSX.Element {
  const { country, topStripProps, centre, headerStaticData, isBasicPage } =
    props
  const [template, quickLinks] = useQuickLinksFromTemplate(props.template)

  const canonical = useGetHrefContext()()
  const meta = getDefaultCentreMetadata({
    centre,
    canonical,
  })

  const metaData = {
    title: meta.title,
    description: meta.description,
    canonical,
  }

  const contactLinks = buildContactLinks(centre)
  useStoredCentre(centre)
  const centreWithLinks = {
    ...centre,
    contactLinks,
  }

  return (
    <>
      <SGLinkedData item={meta.schema} />
      <OpenGraph data={meta.openGraphData} />
      <Layout
        country={country}
        topStrip={topStripProps}
        centre={centreWithLinks}
        quickLinks={quickLinks}
        header={
          isBasicPage
            ? null
            : {
                variant: 'centre',
                data: headerStaticData,
              }
        }
        metaData={metaData}
      >
        <Template template={template} activateH1Title={false} />
      </Layout>
    </>
  )
}
